<template>
  <div>
    <div class="verified m-bj-bgColor m-f-28">
      <div class="flex align-content">
        <div class="verified-label fw">姓 名</div>
        <input type="text" class="verified-input span1" v-model="memberName" placeholder="请输入姓名" />
      </div>
      <div class="flex align-content">
        <div class="verified-label fw">身份证号</div>
        <input type="text"
        class="verified-input span1 border-none" v-model="certNo" placeholder="请输入18位身份证号" />
      </div>
    </div>
      <!-- 协议 -->
    <div class="confirm-order-protocol align-content flex">
      <div class="protocol-check" :class="{'check': selectStatus}" @click="onselect"></div>
      <div class="m-f-24 span1">
        我已阅读并同意
        <span class="hightlight-fontColor" @click="jump('https://pdf.dingxiangzu.com/MODEL/h5/UserInformationAuthorizationLetter.pdf')">《用户信息授权书》</span>
      </div>
    </div>
    <!-- 下一步 -->
    <Button title="下一步" className="submit" @submit="onauthRealName" />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { Toast } from 'vant';

import { useRouter } from 'vue-router';
import API from '@/config/api';
import { verifiedJump } from '@/utils/common';
import Button from '@com/Button.vue';

/** 变量 */
const router = useRouter();
const memberName = ref('');
const certNo = ref('');
const selectStatus = ref(false);

/** 函数 */
const onselect = () => {
  selectStatus.value = !selectStatus.value;
};
const onauthRealName = async () => {
  if (!selectStatus.value) {
    Toast('请勾选协议');
    return;
  }
  if (!memberName.value) {
    Toast('请输入真实姓名');
    return;
  }
  if (certNo.value.length !== 18) {
    Toast('请输入18位身份证号');
    return;
  }
  await API.authRealName({
    certNo: certNo.value,
    memberName: memberName.value,
    handCardId: 1,
    certType: 1,
  });
  verifiedJump(router);
};
const jump = (url) => {
  window.open(url);
};
</script>
<style lang="scss" scoped>
.verified {
  padding:10px 32px;
  margin: 20px auto;
  width: 95%;
  box-sizing: border-box;
  border-radius: 8px;
  > div {
    height: 80px;
    margin-bottom: 10px;
  }
  &-input {
    border: none;
    height: 55px;
    border-bottom: 1px solid #E5E5E5;
  }
  &-label {
    width: 120px;
  }
}
  .submit {
    @include submit;
    width: 96%;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -48%;
    font-size: 32px;
  }
  .confirm-order-protocol {
    margin-left: 20px;
    .protocol-check {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #666;
      position: relative;
      top: -2px;
      margin-right: 10px;
    }
      .check {
        width: 21px;
        height: 21px;
        border-radius: 4px;
        border: 2px solid #666;
        position: relative;
        top: -2px;
        margin-right: 10px;
        background: url("https://file.dingxiangzu.com/alipay/free-event/bill-check.png") no-repeat;
        background-size: 100%;
        border: none;
      }
  }
.border-none {
  border: none;
}
.fw{
  font-weight: bold;
}
</style>
